<template>

<com-content class="pricing">

	<com-intro />
	<com-services v-model="model.services" :level="model.level" :services="data.services" />
	<com-extras v-model="model.extras" />
	<com-summary :disabled="!model.services.length" :services="model.services" :level="model.level" :extras="model.extras" :data="data" />

</com-content>
	
</template>

<script>

export default {

	components: {

		'com-content': () => import('./common/Content'),
		'com-intro': () => import('./pricing/Intro'),
		'com-services': () => import('./pricing/Services'),
		'com-extras': () => import('./pricing/Extras'),
		'com-summary': () => import('./pricing/Summary')

	},

	data: function() {

		return {
			model: {
				level: 0,
				services: [],
				extras: []
			},
			data: {
				services: [
					{
						id: 'registration',
						name: 'Registration',
						icon: 'registration',
						text: 'Adds support for selling of badges and merchandise.',
						fee: 1.00,
						save: false
					},
					{
						id: 'schedule',
						name: 'Schedule',
						icon: 'schedule',
						text: 'Adds support for attendees to organize events and communicate with eachother.',
						fee: 1.00,
						save: false
					},
					{
						id: 'checkout',
						name: 'Checkout',
						icon: 'checkout',
						text: 'Adds support for attendees to checkout games via badge scanning.',
						fee: 0.60,
						save: 0.00
					}
				]
			}
		}

	}

}

</script>

<style scoped>

</style>